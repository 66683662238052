.App {
  display: flex;
  width: 100%;
  position: relative;
}

@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";
@import "https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,500,600,700";

.text-center {
  margin: auto !important;
}

/* General Classes */

.selectable-item {
  cursor: pointer;
}

.selectable-item:hover {
  color: gray;
}

/* End General Classes */

/* ---------------------------------------------------
    SIDEBAR CLASSES
----------------------------------------------------- */

a.nav-link {
  color: white;
}

a.nav-link:hover {
  color: gray;
}

.sidebar {
  min-width: 250px;
  max-width: 250px;
  background: #354052;
  color: #adb5bd;
  margin-left: -250px;
  transition: all 0.5s;
}

.sidebar.is-open {
  margin-left: 0;
  transition: 0.5s;
}

.sidebar-header {
  background: #313b4c;
  color: #adb5bd;
}

.sidebar-nav li {
  list-style: none;
  padding: -100px;
}

.profile-view {
  color: #fff;
  margin-left: 45px;
}

.sidebar ul p {
  color: #fff;
  padding: 10px;
}

.collapse.show,
.collapsing {
  background: #313b4c;
}

.nav-item .svg-inline--fa {
  color: inherit;
}

li a.dropdown-toggle::after {
  display: inline-flex;
  position: relative;
  left: 60%;
  top: 10%;
}

.sidebar-header > button {
  position: relative;
  float: right;
  margin: 0.5em;
  font-size: 2rem;
  cursor: pointer;
  display: none;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

.container-fluid {
  height: fit-content;
}

.sidebar.is-open {
  min-width: 13%;
  max-width: 13%;
  margin-left: 0;
  transition: all 0.5s, height 0s;
}

.sidebar.is-open > .sidebar-header button {
  display: block;
}

li a.dropdown-toggle::after {
  display: inline-block;
  position: relative;
  left: 68%;
}

/* NavBar Classes */

.nav-item {
  color: #354052;
  font-weight: 300;
  font-size: 25px;
}

.nav-item .dropdown {
  color: white;
}

#sidebar-container .nav-item {
  color: white;
}

.navbar-brand {
  font-size: 2rem !important;
}

.navbarContainer.is-open {
  transition: all 0.5s;
}

.navbar-text#scrum-nest-title {
  font-size: 2rem;
}

.navbar-nav {
  z-index: 1;
}

.navbar-center-title {
  position: relative;
  font-size: 2rem;
  left: 4%;
}

.navbar-padding {
  padding: 1.75rem;
}

.navbar-icons {
  cursor: pointer;
}

/* createDialogLabel */

.createDialogLabel {
  font-size: 20px;
  margin-top: 10px;
  margin-left: 20px;
}

.custom-control2 {
  padding: 0px;
  width: 450px;
  margin-top: -25px;
  margin-bottom: 10px;
}

/* Team page */

.container-team {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 300px;
  padding-left: 200px;
  padding-right: 100px;
}

.team-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-left: -30px;
}

.select-nest-row {
  margin-left: 30%;
}

.select-nest-header {
  float: left !important;
  text-align: center !important;
  margin-right: 30px;
}

.input-group {
  width: 75% !important;
}

.btn-add-teammate {
  display: inline-block;
  width: 15% !important;
  margin-left: 10px;
}

.card-pending {
  display: contents;
}

.name-text {
  display: inline-block;
}

.team-line {
  margin-right: 120px;
}

.row-pending {
  display: inherit !important;
  margin-right: 100px !important;
}

.scroll {
  max-height: 470px;
  overflow-y: auto;
}

.col-md-4-pending {
  margin-left: 20px;
  max-width: 90% !important;
}

.member-name-text {
  display: inline-block;
  font-size: 25px;
}

.btn-primary-edit {
  color: #fff !important;
  background-color: #007bff !important;
  border-color: #007bff !important;
  float: right;
  margin-right: 20px;
}

/* End NavBar Classes */

/* Dropdown Classes */

.dropdown-item {
  text-align: center;
  display: flex;
}

.dropdown-item-icon {
  padding-right: 0.5rem;
}
/* End Dropdown Classes */

/* Dialog Classes */

/* TO-DO Dialog CSS Classes Here */

/* End Dialog Classes */

/* Form Control Classes */

.side-by-side {
  display: flex;
}

.form-control-label {
  font-size: 20px;
}

.form-group {
  padding-right: 2%;
  padding-left: 2%;
}

.form-label-padding {
  padding-left: 2%;
}

.form-control {
  display: inline !important;
  width: fit-content !important;
}

.form-control.effort-field {
  width: 5rem !important;
  margin-right: 0.5%;
}

.form-control.dropdown-field {
  width: 12rem !important;
}

.form-control.full-width-field {
  width: 80% !important;
}

/* End Form Control Classes */

/* Amplify Classes */

:host {
  --padding: 0.2rem;
}

.dropdown-item :root {
  --amplify-primary-color: white;
  --amplify-primary-tint: gray;
  --amplify-primary-shade: gray;
  --amplify-primary-contrast: black;
}

/* End Amplify Classes */

/* Board Classes */

.container-height {
  height: 75rem;
}

.max-height {
  height: 100%;
}

#board-container.container .row {
  height: 100%;
}

#board-container.container .col {
  height: 100%;
  border: 0.5px dashed #007bff;
}

#board-container.container {
  max-width: none;
  padding: 1.5rem;
}

#board-container-title h5 {
  font-size: 2rem;
  display: inline-block;
  padding-right: 1rem;
}

#board-container-title {
  border-bottom: 1px solid #007bff;
}

.board-column-properties {
  width: 10% !important;
  overflow-y: hidden;
  padding-bottom: 5%;
}

#user-story-container {
  overflow-y: auto;
  height: 100%;
}

#user-story-container::-webkit-scrollbar {
  display: none;
}

.nest-title {
  color: slategray;
  font-weight: 350;
}

.user-story-desc-text {
  font-size: 0.8rem !important;
}

.user-story-title-text {
  font-size: 1rem !important;
}

.user-story-subtitle-text {
  font-size: 0.9rem !important;
}

/* End Board Classes */

/* Generic Form Controls */
.disabled-field {
  pointer-events: none;
  opacity: 0.6;
}
/* User Story Page Classes */

.description-field-width-height {
  width: 100% !important;
  height: 20rem !important;
}

.left-container {
  width: 75%;
  float: left;
}

.file-field {
  width: 75% !important;
}

.attachments-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.attachment-icon {
  cursor: pointer;
  color: #007bff;
}

.attachment-icon:hover {
  color: gray;
}

.comments-field {
  width: 100% !important;
  height: 12rem !important;
  overflow-y: auto;
}

.comment-field {
  width: 100% !important;
  height: 6rem !important;
  /* margin: auto !important; */
}

.comment-label {
  display: inherit !important;
  width: 100%;
}

.center {
  margin: auto;
  width: 50%;
}

/* End User Story Page Classes */

/* Dropdown CSS for User Story Priority */
.priority-none {
  background-color: #fff;
}

.priority-low:hover {
  background-color: rgb(255, 255, 255);
}

.priority-low {
  background-color: rgb(0, 255, 76) !important;
}
.priority-medium {
  background-color: rgb(251, 255, 0) !important;
}
.priority-high {
  background-color: rgb(250, 124, 6) !important;
}
.priority-urgent {
  background-color: rgb(255, 1, 1) !important;
}
